import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/Button.scss"
import airplane from '../assets/Images/airplane.svg'
const Button = ({ text, link, color = "", type = "" }) => {
    return (

        <>
            {link ? <Link to={`/${link}`}>
                <button className={`cta ${color}`}>
                    <span>{text}

                    </span>
                    <img src={airplane} loading='lazy'/>
                </button>
            </Link> : <button className={`cta ${color}`} type={type}>
                <span>{text}

                </span>
                <img src={airplane} loading='lazy'/>
            </button>}

        </>
    );
};

export default Button;