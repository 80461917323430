import React from 'react';
import '../styles/WhereWeServe.scss';

const WhereWeServe = () => {



  return (
    <>
      <div className="where-we-serve">
        <h1>WHERE WE SERVE</h1>
  
        <section className="where-we-serve__content">
          <div className="details">
            <h3>Tbilisi International Airport</h3>
            <a href="https://www.Flyinglavion.ge" style={{ color: '#d4a72a', fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline italic' }}>
            flyinglavion.ge
            </a>

            <p>
              L' Avion is premium class restaurant what is ready to provide premium class catering for any flight at Tbilisi International Airport. Our staf is at you service around the clock. All you need is to select required dishes from our menu, indicate delivery details, order, pay for it and we will deliver it to your aircraft.
              <br></br>​
              <br></br>​
              Inspired by the excitement of travel, L’Avion takes you on a premium dining experience just steps from Tbilisi International Airport. The name, derived from the French word for "airplane," reflects our spirit of adventure, blending international comfort food with authentic Georgian flavors.
              <br></br>​
              <br></br>​
              Whether you're a traveler, business guest, or local, L’Avion offers a modern yet relaxed setting where you can enjoy everything from quick bites to a refined dining experience.
              <br></br>​
              <br></br>​
              Our 24/7 catering service ensures that exceptional meals are always within reach -whether at our restaurant or delivered directly to your aircraft. We are only caterer in Tbilisis International Airport to deliver any item from our menu maximum in 6 hours from its order. 
​             <br></br>​
              You have to taste a culture to understand it!
            </p>

          </div>

        </section>

        <section className="where-we-serve__content">

          <div className="details">
            <h3>Kutaisi International Airport</h3>
            <p>
              <i>Comming Soon...</i>
            </p>

          </div>
        </section>

        <section className="where-we-serve__content">
          <div className="details">
            <h3>Batumi International Airport</h3>
            <p>
              <i>Comming Soon...</i>
            </p>

          </div>
        </section>

      </div>

    </>
  );
};

export default WhereWeServe;
