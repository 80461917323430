import React, { forwardRef } from 'react';
import '../styles/Footer.scss';
import logo from '../assets/Images/caterer_logo_white.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = forwardRef((props, ref) => {
  return (
    <footer className="footer" ref={ref}>
      <div className="footer-container">
        <Link to='/'>
          <div className="footer-logo">
            <img src={logo} alt='logo' loading="lazy" />
          </div>
        </Link>

        <div className="footer-contact">
          <p>
            <a href="tel:+955553535353">+995 591 30 38 37</a>
          </p>
          <p>
            <a href="mailto:your@caterer.ge">your@caterer.ge</a>
          </p>
          <p> <a href="https://maps.app.goo.gl/v2mSKPKg11bAhB3S8" target="_blank" rel="noopener noreferrer" aria-label="Maps">Tbilisi International Airport 0158</a> </p>
          <p>Tbilisi, Georgia</p>
        </div>
        <div className="footer-links">

          <div className='footer-links-soc'>
            <a href="https://www.facebook.com/profile.php?id=61558698676136" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FontAwesomeIcon icon={faSquareFacebook} />
            </a>
            <a href="https://www.instagram.com/caterer.ge/?utm_source=ig_web_button_share_sheet" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>

    </footer>
  );
});

export default Footer;
