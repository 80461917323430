import React, { useRef } from 'react';
import '../styles/About.scss';
import image10 from '../assets/Images/Cheff.webp';
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Button from '../components/Button';
const About = () => {


  const detailsRef = useRef(null);
  const middleRef = useRef(null);


  const detailsInView = useInView(detailsRef, { once: true });
  const middleInView = useInView(middleRef, { once: true });


  return (
    <>

      <div className="about">

        <div className='about__image'></div>

        <section className="about__main">
          <div className="about__intro">
            <h1>Our Story: Redefining Airline Catering in Sakartvelo and not only</h1>
            <p>
              Georgian hospitality is renowned worldwide for its warmth, generosity, and depth of tradition. While restaurants, bistros, cafes, and other places on the ground are overflowing with mouth-watering dishes, the Georgian aviation industry still lacks quality onboard catering. This is something that we've noticed and taken an interest in after many years of experience in the aviation market.

            </p>


            <motion.p className="about__details" ref={detailsRef}
              style={{ visibility: detailsInView ? 'visible' : 'hidden' }}
              initial={{ y: 100, opacity: 0 }}
              animate={detailsInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}>
              Recognizing this gap, <strong>Caterer</strong>  was born. Our platform empowers aircraft operators to curate exquisite meals for their flights with unprecedented ease and transparency. No hidden costs, no unexpected surprises—just gastronomic excellence delivered punctually every single time.

            </motion.p>

          </div>




          <motion.section className="about__middle" ref={middleRef}
            style={{ visibility: middleInView ? 'visible' : 'hidden' }}
            initial={{ y: 100, opacity: 0 }}
            animate={middleInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h3>The Maestro Behind the Menu</h3>
            <p>At the heart of Caterer is our visionary chef, Levan Kobiashvili. With years of experience and a deep passion for culinary arts, Chef Levan, alongside his dedicated team, crafts unique Georgian dishes that are true to their traditions and sometimes with a twist. Each dish is a reimagined tradition, telling the stories, evoking memories, and creating new traditions at 30,000 feet. Each catering provider and location what will be added to our platform list will be selected and designated by our Chef, only after detail inspection and approval we will add new location and new provider to our system.
            </p>
            <img src={image10} loading="lazy" alt="Chef working" />
            <h3>
              Where, When, and How
            </h3>
            <p>
              First platform that we are introducing to the aviation market is www.flyingchef.ge,  it is an embodiment of luxury and convenience. Launched at Tbilisi International Airport, this exclusive high-class catering service allows clients to explore a curated selection of dishes that promise to tantalize their taste buds. We decided to go with a minimalistic, user-centric design to make it even more comfortable for users to focus on selecting, ordering, and delivering top-tier in-flight catering.
              <br />
              Every dish on our platform tells a story. From the traditional methods used in its preparation to the intricate flavors that dance on your palate, each meal is a sensory journey. Detailed descriptions accompany each dish, providing insights into its creation, taste profile, serving suggestions, weight, and even sommelier advice on the perfect wine pairing.

            </p>
          </motion.section>

          <section className='about__commitment'>
            <h3>Our Commitment
            </h3>
            <p>
              Caterer.ge is dedicated to transforming the way passengers perceive in-flight dining. We cater to those who value their travel experience and are ready to challenge the stereotypical perceptions of airline food. Our commitment to quality, transparency, and exceptional service ensures that your culinary experience with us is nothing short of extraordinary.

            </p>
          </section>
          <section className='about__summary'>

            <Button text={"Where We Serve"} link={'whereweserve'} color='black' />

          </section>
        </section>
      </div>
    </>
  );
};

export default About;
