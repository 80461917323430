import React, { useEffect, useState, useRef } from 'react';
import '../styles/Contact.scss';
import emailjs from '@emailjs/browser';
import Header from '../components/Header';
import Button from '../components/Button';
import Footer from '../components/Footer';

const Contact = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#4b858e';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);



  const form = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let validationErrors = {};

    if (!name) validationErrors.name = 'Name is required';
    if (!email) {
      validationErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      validationErrors.email = 'Invalid email format';
    }
    if (!message) validationErrors.message = 'Message is required';

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});

      const templateParams = {
        from_name: name,
        from_email: email,
        to_name: 'Caterer',
        message: message,
      };

      const serviceId = process.env.REACT_APP_SERVICE_ID
      const templateId = process.env.REACT_APP_TEMPLATE_ID;
      const publicKey = process.env.REACT_APP_PUBLIC_KEY;

      emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
          console.log('Email sent successfully!', response);
          setResponseMessage('Email sent successfully!');
          setName('');
          setEmail('');
          setMessage('');
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          setResponseMessage('Error sending email. Please try again later.');
        });


      setName('');
      setEmail('');
      setMessage('');
    }
  };

  return (
    <>
      {/* <Header isDark={true} page="contact" /> */}
      <div className="contact-form">
        <form ref={form} onSubmit={handleSubmit} className="contact-form__body">
          <div className='contact-form-top'>
            <div className='contact-form-top-inputs'>
              <div className="contact-form__group">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder='Name'
                  id="name"
                  name="user_name"
                  required
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>

              <div className="contact-form__group">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Email'
                  id="email"
                  name="user_email"
                  required
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
            </div>

            <div className='Contact-form-top-text'>
              <h2>HAVE A QUESTION?</h2>
              <h6>Fill out our form and we will get back to you!</h6>
            </div>
          </div>

          <div className="contact-form__group">
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              name="user_message"
              rows="4"
              placeholder="Leave a message"
              required
            ></textarea>
            {errors.message && <p className="error">{errors.message}</p>}
          </div>
          <Button text={'Send'} type={'submit'} />
        </form>
        {responseMessage && <p className="response-message">{responseMessage}</p>}
      </div>

    </>
  );
};

export default Contact;
