import React, { useEffect, useRef } from 'react';
import '../styles/Home.scss';

import image1 from "../assets/Images/img4.webp";
import image2 from '../assets/Images/khinkali_rc-735x735-removebg 1.png';
import Button from '../components/Button';
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

const Home = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const value = window.scrollY * 0.25;
      if (imageRef.current) {
        imageRef.current.style.transform = `rotate(${value}deg)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const tasteRef = useRef(null);
  const middleText1Ref = useRef(null);
  const middleText2Ref = useRef(null);
  const middleText3Ref = useRef(null);
  const serveRef = useRef(null);

  const middleGalleryRef = useRef(null);
  const bottomRef = useRef(null);

  const tasteInView = useInView(tasteRef, { once: true });
  const middleText1InView = useInView(middleText1Ref, { once: true });
  const middleText2InView = useInView(middleText2Ref, { once: true });
  const middleText3InView = useInView(middleText3Ref, { once: true });
  const serveInView = useInView(serveRef, { once: true });


  const middleGalleryInView = useInView(middleGalleryRef, { once: true });
  const bottomInView = useInView(bottomRef, { once: true });

  return (
    <>
      <div className="home">

        <motion.section
          className="home__taste"
          ref={tasteRef}
          style={{ visibility: tasteInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={tasteInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <img src={image1} alt="Taste Image" className='home__taste-img' loading="lazy" />
          <div className="home__taste-text">
            <h1>Bringing Unique Dining Experience to the Aviation Market Reimagined</h1>
            <p>Caterer is a Georgian brand transforming in-flight dining, providing premium quality meals that exceed expectations and enhance passengers’ journeys. We believe that every flight should be an opportunity to savor exceptional food. Our goal is to enhance their journey with an elevated dining experience that connects them to our culture, even at altitude.
            </p>
            <Button text={'More About Us'} link={'about'} color='black' />
          </div>
        </motion.section>

        <section className="home__middle">
          <h1>Three reason we do what we do </h1>
          <div className='home__middle-cusatis'>
            <motion.div
              className="home__middle-text"
              ref={middleText1Ref}
              style={{ visibility: middleText1InView ? 'visible' : 'hidden' }}
              initial={{ y: 100, opacity: 0 }}
              animate={middleText1InView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <h3>Customer Satisfaction - Filling The Gaps in Georgian Aviation Catering</h3>
              <p>We’ve noticed how the Georgian aviation market has experienced intense growth for years. We witnessed travelers visiting Georgia and Georgians traveling outside the country, yet the number and quality of catering suppliers haven't kept pace with this surge.
                <br />
                To fill in the gaps in Georgian aviation catering, our team created a cutting-edge catering ordering and delivery platform designed to elevate the quality and presentation of in-flight meals. By focusing on exceptional quality, stunning presentation, and customer-centric service, we're not just feeding passengers – we're transforming their journey.
              </p>
              <Button text={'See how it turned out'} link={'whereweserve'} />
            </motion.div>

            <motion.div
              className="home__middle-gallery"
              ref={middleGalleryRef}
              style={{ visibility: middleGalleryInView ? 'visible' : 'hidden' }}
              initial={{ y: 100, opacity: 0 }}
              animate={middleGalleryInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <img ref={imageRef} src={image2} alt="Rolling Khinkalis" loading="lazy" />

            </motion.div>
          </div>
          <div className='home__middle-taste'>
            <motion.div
              className="home__middle--taste-text"
              ref={middleText2Ref}
              style={{ visibility: middleText2InView ? 'visible' : 'hidden' }}
              initial={{ y: 100, opacity: 0 }}
              animate={middleText2InView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <h3>Leave Your Expectations on the Ground: Taste Your Taste </h3>
              <p>In the world of catering, preconceptions often limit our culinary experiences. One of the most difficult things is breaking down the stereotypical views and expectations around food. We like a good challenge, and as pioneers in Georgia’s aviation catering industry, we encourage the passengers to leave their expectations on the ground and embark on a journey to flavor discovery. </p>

            </motion.div>
            <motion.div
              className="home__middle-taste-text"
              ref={middleText3Ref}
              style={{ visibility: middleText3InView ? 'visible' : 'hidden' }}
              initial={{ y: 100, opacity: 0 }}
              animate={middleText3InView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <h3>Extraordinary Experience in a Well-Known Environment</h3>
              <p>We like to say that we have the pleasure and ability to fly thanks to humanity’s tireless work. Continuing this work, airlines create the utmost comfort for their customers, including the dining experience. We believe that unforgettable, special, fresh, and custom-made dishes can make the best out of your flight. That’s why we work hard to bring the magic of Georgian flavors as high as aircraft goes.</p>

            </motion.div>
          </div>
        </section>

        <motion.section className='home__serve'
          ref={serveRef}
          style={{ visibility: serveInView ? 'visible' : 'hidden' }}
          initial={{ y: 100, opacity: 0 }}
          animate={serveInView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}>
          <Button text={"Where We Serve"} link={'whereweserve'} color='black' />
        </motion.section>

      </div>

    </>
  );
};

export default Home;
